
import React, { useState } from "react"
import Bubble from "../components/bubble"
import { StaticImage } from "gatsby-plugin-image"
import { Parallax } from 'react-scroll-parallax';
import Wave from "../assets/svg/wave.svg";

const Details = (props) => {
    
    return(
        <>
            <h2 className="detailsTitle">How it works</h2>
            <div id='howitworks' className="bg-white w-full h-[auto] relative z-[60] detailsContainer md:items-center items-start">
                <Parallax speed={30} style={{width:'100%',height:'100%', position:'absolute'}}>
                    <span className="detailsCircle"></span>
                </Parallax>
                <span className="md:hidden flex w-[95vw]  detailsItemContainer2">

                    <span className="detailsItem"> 
                        <h3>iGen® Digital Control Valve</h3>
                        <h3>SilverShield® HYgiene® monitor</h3>
                    </span>
                    <span className="detailsItem"> 
                        <h3>Three piece filter media tank</h3>
                        <p>Inner liner of Polyethylene</p>
                        <p>Outer layer of fiberglass</p>
                        <p>Stainless Steel Outer Cover</p>
                    </span>
                    <span className="detailsItem"> 
                        <h3>Microsilver Bacteriostasis Technology</h3>
                        <p>Microscopic particles of silver are embeded into SilverShield® HYgiene® media to inhibit bacteria growth within the filter media bed</p>
                    </span>


                    <span className="detailsItem md:hidden flex"> 
                        <h3>SilverShield® HYgiene® filter</h3>
                        <p>SilverShield® HYgiene® media reduces objectionable chlorine and chloramine tastes and odors</p>
                    </span>
                    <span className="detailsItem md:hidden flex"> 
                        <h3>Softener and conditioner</h3>
                        <p>High capacity S-759 monospheric resin has uniform sized beads for higher flow rates and efficiencies</p>
                        <p>Reduces iron, turbidity and hardness</p>
                    </span>
                    <span className="detailsItem md:hidden flex"> 
                        <h3>Clarifier</h3>
                        <p>Silica gravel polishes water to sparkling clarity</p>
                    </span>
                    

                </span>


                <span className="hidden md:flex w-[35vw] detailsItemContainer">

                    <span className="detailsItem"> 
                        <h3>iGen® Digital Control Valve</h3>
                        <h3>SilverShield® HYgiene® monitor</h3>
                    </span>
                    <span className="detailsItem"> 
                        <h3>Three piece filter media tank</h3>
                        <p>Inner liner of Polyethylene</p>
                        <p>Outer layer of fiberglass</p>
                        <p>Stainless Steel Outer Cover</p>
                    </span>
                    <span className="detailsItem"> 
                        <h3>Microsilver Bacteriostasis Technology</h3>
                        <p>Microscopic particles of silver are embeded into SilverShield® HYgiene® media to inhibit bacteria growth within the filter media bed</p>
                    </span>
                </span>
                <span className="w-[50%] md:w-[30vw] relative hidden md:flex items-center justify-center">
                    
                    <StaticImage
                        src="../images/filter2.png"
                        alt="Aquaduo filter inside"
                        placeholder="blurred"
                        layout="constrained"
                    />
                    
                </span>
                <span className="hidden md:block w-[35vw] detailsItemContainer">

                    <span className="detailsItem2"> 
                        <h3>SilverShield® HYgiene® filter</h3>
                        <p>SilverShield® HYgiene® media reduces objectionable chlorine and chloramine tastes and odors</p>
                    </span>
                    <span className="detailsItem2"> 
                        <h3>Softener and conditioner</h3>
                        <p>High capacity S-759 monospheric resin has uniform sized beads for higher flow rates and efficiencies</p>
                        <p>Reduces iron, turbidity and hardness</p>
                    </span>
                    <span className="detailsItem2"> 
                        <h3>Clarifier</h3>
                        <p>Silica gravel polishes water to sparkling clarity</p>
                    </span>

                </span>



                <Bubble  color={'#64C8EA'} size='40px' border='7px' positionX='15%' positionY='60%' speed={20} blur={0} initX={0} finalX={-50}/>
                <Bubble  color={'#64C8EA'} size='40px' border='12px' positionX='5%' positionY='35%' speed={40} blur={0.3} initX={0} finalX={200}/>
                <Bubble  color={'#64C8EA'} size='40px' border='10px' positionX='70%' positionY='10%' speed={15} blur={0} initX={0} finalX={-300}/>
                <Bubble  color={'#64C8EA'} size='40px' border='8px' positionX='55%' positionY='60%' speed={35} blur={0} initX={0} finalX={100}/>  
            </div>


            <div className="tankContainer  lg:px-[20%] px-[5%] mt-[10%] flex-col md:flex-row">
                <span className="w-[100%] md:w-[50vw] relative flex items-center justify-center">
                    
                    <StaticImage
                        src="../images/filter3.png"
                        alt="Aquaduo filter inside"
                        placeholder="blurred"
                        layout="constrained"
                    />
                    
                </span>
           
                <span className="w-[100%] md:w-[50vw] tanktext z-[300]">
                    <p>
                        Our AquaDuo Ro, which is installed underneath the kitchen sink, delivers unlimited alkaline water that contains mineral. Alkaline water contains minerals such as magnesium and calcium, as well as powerful antioxidants, the consumption of which helps prevent the growth of free radicals that damage cells, a situation that can accelerate the aging process.
                    </p>
                </span>
                

                <Bubble  color={'#64C8EA'} size='40px' border='7px' positionX='15%' positionY='60%' speed={20} blur={0} initX={0} finalX={-50}/>
                <Bubble  color={'#64C8EA'} size='40px' border='12px' positionX='5%' positionY='35%' speed={40} blur={0.3} initX={0} finalX={200}/>
                <Bubble  color={'#64C8EA'} size='40px' border='10px' positionX='70%' positionY='10%' speed={15} blur={0} initX={0} finalX={-300}/>
                <Bubble  color={'#64C8EA'} size='40px' border='8px' positionX='55%' positionY='60%' speed={35} blur={0} initX={0} finalX={100}/>  
            
                <span className="absolute bottom-[5px] translate-y-[100%] w-full h-auto z-[200]">
                    <Wave/>
                </span>
                
            </div>

            
        </>
        
    )
}
export default Details