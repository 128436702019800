
import React, { useState } from "react"

import Bubble from "../components/bubble"
import Waves from "../assets/svg/waves.svg";
import Drop from "../assets/svg/drop.svg";
import { StaticImage } from "gatsby-plugin-image"
import { Parallax } from 'react-scroll-parallax';


const Nutrients = (props) =>{


    return(
        <div  className="container2 w-full h-[auto] relative z-[10]">
            <div className="nutrientComponents  lg:px-[20%]  px-[5%] text-center">
                <h2>Alkanine water in your home </h2>
                <div className="nutrientComponentsInner w-full flex md:flex-row flex-col mt-[15%]">
                    <div className="nutrientsInner md:w-[50%] w-full">
                        <h3>Nutrients your body needs</h3>
                        <span className="nutrientsContainer flex-row flex-wrap flex">
                            <span className="nutrient flex-col flex items-center justify-between mt-[10%] md:basis-[33.3%] basis-[50%]">
                                <Drop/>
                                <p>PH+</p>
                            </span>
                            <span className="nutrient flex-col flex items-center justify-between mt-[10%] md:basis-[33.3%] basis-[50%]">
                                <Drop/>
                                <p>Potassium</p>
                            </span>
                            <span className="nutrient flex-col flex items-center justify-between mt-[10%] md:basis-[33.3%] basis-[50%]">
                                <Drop/>
                                <p>Calcium</p>
                            </span>
                            <span className="nutrient flex-col flex items-center justify-between mt-[10%] md:basis-[33.3%] basis-[50%]">
                                <Drop/>
                                <p>Oxygen</p>
                            </span>
                            <span className="nutrient flex-col flex items-center justify-between mt-[10%] md:basis-[33.3%] basis-[50%]">
                                <Drop/>
                                <p>Antioxidant</p>
                            </span>
                        </span>
                    </div>
                    <div className="md:w-[50%] w-full mt-[20%] md:mt-[0]">
                        <h3>AQUADUO RO:</h3>
                        <ul>
                            <li>Has antioxidants</li>
                            <li>Hydrates faster</li>
                            <li>Eliminates toxins</li>
                            <li>Provides more energy</li>
                        </ul>
                    </div>
                </div>
                

            </div>
            <Bubble  color={'#ffffff'} size='40px' border='7px' positionX='15%' positionY='60%' speed={20} blur={0} initX={0} finalX={-50}/>
            <Bubble  color={'#ffffff'} size='40px' border='12px' positionX='5%' positionY='35%' speed={40} blur={0.3} initX={0} finalX={200}/>
            <Bubble  color={'#ffffff'} size='40px' border='10px' positionX='70%' positionY='10%' speed={15} blur={0} initX={0} finalX={-300}/>
            <Bubble  color={'#ffffff'} size='40px' border='8px' positionX='55%' positionY='60%' speed={35} blur={0} initX={0} finalX={100}/>

            
            <span className="absolute bottom-[-10px] w-full h-auto">
                <Waves/>
            </span>
        </div>
    )

}
export default Nutrients